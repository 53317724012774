import React, {useEffect} from 'react';
import gsap from "gsap"
import SplitType from 'split-type';
const ProcessSection1 = (props) => {
    useEffect(() => {
        let titleBlock = gsap.timeline({defaults: {ease: "Power4.easeInOut"}});
        titleBlock.to(".process-page .title-description-block",1.4, {
            marginTop: 0,
            ease: "Power4.easeInOut",
            delay: 2.2,
            stagger: {
                amount: 0.1
            }
        })

        const processMainTitle = document.querySelector(".process-page .title-description-block .section-title");
        const processMainDescription = document.querySelector(".process-page .title-description-block .section-description");

        gsap.set(processMainTitle, { autoAlpha: 1 });

        const splitSectionTitle = new SplitType(processMainTitle, {
            type: "words,lines",
            wordsClass: "words",
            linesClass: "lines",
        })

        const tlSectionTitle = gsap.timeline({});
        tlSectionTitle.from(splitSectionTitle.words,{
          duration: 1.3,
          yPercent: 150,
          ease: "Power4.easeInOut",
          rotationX: 0.5,
          rotationY: 0.5,
          z: 0.5,
          delay: 1.8,
          stagger: {
            amount: 0.1
          }
        });

        gsap.set(processMainDescription, { autoAlpha: 1 });
        const splitSectionDescription = new SplitType(processMainDescription, {
            type: "words,lines",
            wordsClass: "words",
            linesClass: "lines",
        })
        const tlSectionDescription = gsap.timeline({});
        tlSectionDescription.from(splitSectionDescription.words,{
          duration: 1.3,
          yPercent: 150,
          ease: "Power4.easeInOut",
          rotationX: 0.5,
          rotationY: 0.5,
          z: 0.5,
          delay: 2,
          stagger: {
            amount: 0.1
          }
        });

        let tlImageBlock = gsap.timeline({defaults: {ease: "Power4.easeInOut"}});
        tlImageBlock.to(".process-page .title-description-block .image-block", 1.3, {
            opacity: 1,
            ease: "Power4.easeInOut",
            delay: .7,
            stagger: {
                amount: 0.1
            }
        })

    },[])
    return (
        <section className="section-1">
            <div className="container">
                <div className="title-description-block">
                    <div className="row">
                        <div className="col-lg-16">
                            <h1 className="h1 section-title normal-spacing">{props.mainTitle}</h1>
                            <p className="section-description">{props.mainDescription}</p>
                        </div>
                        <div className="col-lg-1"></div>
                        <div className="col-lg-7">
                            <div className="image-block">
                                <img
                                    src={props.image1x?.sourceUrl}
                                    srcSet={props.image2x?.sourceUrl + " 2x, " + props.image1x?.sourceUrl + " 1x"}
                                    width={props.image1x?.width}
                                    alt={props.image1x?.altText}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ProcessSection1